import React, { FC, memo, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
  MobileStepper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { OrbyButton } from 'orby-ui/src';
import { Announcement } from 'protos/pb/orby_internal/orby_internal_service';
import './AnnouncementDetailPage.css';

interface AnnouncementPopupProps {
  show: boolean;
  announcement?: Announcement;
  images?: Map<string, string>;
  handleClose: () => void;
}

const AnnouncementPreviewDialog: FC<AnnouncementPopupProps> = ({
  show,
  announcement,
  images,
  handleClose,
}) => {
  // selectedPageIndex is used to show content blocks of the announcement
  // user can click Next/Previous button to switch between pages
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);

  useEffect(() => {
    if (show) {
      setSelectedPageIndex(0);
    }
  }, [show]);

  const handlePrevious = () => {
    if (selectedPageIndex === 0) {
      return;
    }
    setSelectedPageIndex(selectedPageIndex - 1);
  };

  const handleNext = () => {
    if (announcement?.contentBlocks?.length === selectedPageIndex + 1) {
      return;
    }
    setSelectedPageIndex(selectedPageIndex + 1);
  };

  const handleURLTransform = (url: string, key: string) => {
    if (key === 'src' && images && images.has(url)) {
      return images.get(url);
    }
    return url;
  };

  function customMarkdownLink({ ...props }) {
    return (
      <a {...props} target='_blank'>
        {props.children}
      </a>
    );
  }

  const renderPageButton = () => {
    if ((announcement?.contentBlocks?.length ?? 0) > 1) {
      if (selectedPageIndex === 0) {
        return (
          <OrbyButton
            onClick={handleNext}
            size='large'
            variant='primary-contained'
            sx={{ width: '100%', margin: '16px' }}
            label='Next'
          />
        );
      } else if (
        selectedPageIndex + 1 ===
        announcement?.contentBlocks?.length
      ) {
        return (
          <Stack
            direction='row'
            spacing={2}
            sx={{ width: '100%', margin: '16px' }}
          >
            <OrbyButton
              onClick={handlePrevious}
              size='large'
              variant='monochrome-outline'
              sx={{ width: '50%' }}
              label='Previous'
            />
            <OrbyButton
              onClick={handleClose}
              size='large'
              variant='primary-contained'
              sx={{ width: '50%' }}
              label='Finish'
            />
          </Stack>
        );
      }
      return (
        <Stack
          direction='row'
          spacing={2}
          sx={{ width: '100%', margin: '16px' }}
        >
          <OrbyButton
            onClick={handlePrevious}
            size='large'
            variant='monochrome-outline'
            sx={{ width: '50%' }}
            label='Previous'
          />
          <OrbyButton
            onClick={handleNext}
            size='large'
            variant='primary-contained'
            sx={{ width: '50%' }}
            label='Next'
          />
        </Stack>
      );
    }
    return (
      <OrbyButton
        onClick={handleClose}
        size='large'
        variant='primary-contained'
        sx={{ width: '100%', margin: '16px' }}
        label='Got it'
      />
    );
  };

  return (
    <Dialog open={show} onClose={handleClose}>
      {announcement?.header?.data && (
        <DialogTitle sx={{ width: '552px', padding: '24px 24px 8px 24px' }}>
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            className={'custom-markdown '}
            components={{
              a: customMarkdownLink,
            }}
            urlTransform={handleURLTransform}
          >
            {announcement?.header?.data}
          </ReactMarkdown>
        </DialogTitle>
      )}
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={(theme) => ({
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme.palette.grey[500],
        })}
      >
        <CloseIcon />
      </IconButton>
      {announcement?.contentBlocks?.length && (
        <DialogContent sx={{ width: '552px', padding: '0 24px' }}>
          {announcement?.contentBlocks?.at(selectedPageIndex)?.body?.data && (
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              className={'custom-markdown'}
              components={{
                a: customMarkdownLink,
              }}
              urlTransform={handleURLTransform}
            >
              {announcement?.contentBlocks?.at(selectedPageIndex)?.body?.data}
            </ReactMarkdown>
          )}
          {(announcement?.contentBlocks?.length ?? 0) > 1 && (
            <MobileStepper
              variant='dots'
              steps={announcement?.contentBlocks?.length ?? 0}
              position='static'
              activeStep={selectedPageIndex}
              sx={{ justifyContent: 'center' }}
              nextButton={<></>}
              backButton={<></>}
            />
          )}
        </DialogContent>
      )}
      <DialogActions>{renderPageButton()}</DialogActions>
    </Dialog>
  );
};

export default memo(AnnouncementPreviewDialog);
