import { Empty } from 'protos/google/protobuf/empty';
import {
  CreateFeatureFlagRequest,
  DeleteFeatureFlagRequest,
  FeatureFlag,
  FeatureFlagsClientImpl,
  GetFeatureFlagRequest,
  GetFeatureFlagsForOrgAndUserRequest,
  GetFeatureFlagsForOrgAndUserResponse,
  ListFeatureFlagsRequest,
  ListFeatureFlagsResponse,
  UpdateFeatureFlagRequest,
} from 'protos/pb/v1alpha2/feature_flag_service';
import { getMetaData, rpcWithErrorHandling } from '../utils/RpcUtils';
import { storageService } from './StorageService';

export class FeatureFlagService {
  private static instance: FeatureFlagService;

  private static client = new FeatureFlagsClientImpl(rpcWithErrorHandling);

  public static getInstance(): FeatureFlagService {
    if (!this.instance) {
      this.instance = new FeatureFlagService();
    }
    return this.instance;
  }

  async getFeatureFlags(
    req: ListFeatureFlagsRequest,
  ): Promise<{ response?: ListFeatureFlagsResponse; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await FeatureFlagService.client.ListFeatureFlags(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async createFeatureFlag(
    req: CreateFeatureFlagRequest,
  ): Promise<{ response?: FeatureFlag; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await FeatureFlagService.client.CreateFeatureFlag(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async updateFeatureFlag(
    req: UpdateFeatureFlagRequest,
  ): Promise<{ response?: FeatureFlag; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await FeatureFlagService.client.UpdateFeatureFlag(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async deleteFeatureFlag(req: DeleteFeatureFlagRequest): Promise<Empty> {
    const authorization = await storageService.getAuthorizationHeader();
    return await FeatureFlagService.client.DeleteFeatureFlag(
      req,
      getMetaData({ authorization }),
    );
  }

  async getFeatureFlag(
    req: GetFeatureFlagRequest,
  ): Promise<{ response?: FeatureFlag; error?: Error }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response = await FeatureFlagService.client.GetFeatureFlag(
        req,
        getMetaData({ authorization }),
      );
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }

  async getFeatureFlagsForOrgAndUser(
    req: GetFeatureFlagsForOrgAndUserRequest,
  ): Promise<{
    response?: GetFeatureFlagsForOrgAndUserResponse;
    error?: Error;
  }> {
    const authorization = await storageService.getAuthorizationHeader();
    try {
      const response =
        await FeatureFlagService.client.GetFeatureFlagsForOrgAndUser(req, {
          authorization,
        } as any);
      return { response };
    } catch (error) {
      return { error: error as Error };
    }
  }
}

export const featureFlagService = FeatureFlagService.getInstance();
