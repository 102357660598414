import { grpc } from '@improbable-eng/grpc-web';
import { GrpcWebImpl } from 'protos/pb/v1alpha2/dashboard_service';
import { Observable } from 'rxjs';

export const rpc = new GrpcWebImpl(process.env.REACT_APP_GRPC_URI as string, {
  transport: grpc.CrossBrowserHttpTransport({
    withCredentials: true,
  }),
});

/** capture all RPC errors and re-throw with our own {@link RpcError} instead. */
export const rpcWithErrorHandling = {
  unary: async <T extends grpc.UnaryMethodDefinition<any, any>>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> => {
    try {
      const response = await rpc.unary(methodDesc, request, metadata);
      return response;
    } catch (e: any) {
      if (e?.code === grpc.Code.Unauthenticated) {
        throw new Error();
      } else {
        throw new Error(e);
      }
    }
  },
  invoke: <T extends grpc.UnaryMethodDefinition<any, any>>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Observable<any> => {
    return new Observable((observer) => {
      rpc.invoke(methodDesc, request, metadata).subscribe(
        (response) => {
          observer.next(response);
        },
        (error) => {
          if (error?.code === grpc.Code.Unauthenticated) {
            observer.error(new Error());
          } else {
            observer.error(new Error(error));
          }
        },
        () => {
          observer.complete();
        },
      );
    });
  },
};

export const getMetaData = (data: any) => {
  return new grpc.Metadata({ ...data });
};
